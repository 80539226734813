@media (max-width: 450px) {
    .shortened-text {
        width: 70px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.k-expander-content {
    padding-top: 0;
    padding-bottom: 0;
}